import * as React from 'react'
//import { Link } from "gatsby"
import Layout from '/src/components/layout'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faLanguage, faPlug, faQuestionCircle, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'

const LaddskolaLaddifyPage = () => {
    return (
        <Layout pageTitle="Hur använder du Laddify?">

            <p>XXX</p>
            
        </Layout>
    )
}

export default LaddskolaLaddifyPage